.auth-wrapper {
  .lavalite-bg {
    height: 100vh;
    position: relative;
    width: 100%;
    background: url('/libs/img/logo_grande.png') #F2F3F5;
    background-size: 500px;
    background-repeat: no-repeat;
    background-position: center;
    /*
	    .lavalite-overlay {
		    position: absolute;
		    width: 100%;
		    height: 100%;
		    top: 0;
		    left: 0;
		    background: linear-gradient(135deg,rgba(32, 177, 204, .85) 0%,rgba(32, 177, 204, .85) 100%);
		}
		*/
  }
  .authentication-form {
    font-size: 0.9rem;
    width: 70%;
    display: block;
    padding: 100px 0;
    .logo-centered {
      width: 60px;
      margin: 0 auto;
      margin-bottom: 40px;
    }
    h1 {
      font-family: 'Montserrat', Sans-serif;
      text-align: center;
      font-weight: bold;
      color: $rojo;
    }
    h3 {
      font-size: 20px;
      color: #272d36;
      margin-bottom: 20px;
    }
    .form-group {
      position: relative;
      .form-control {
        padding-left: 40px;
        & ~ i {
          position: absolute;
          top: 10px;
          left: 15px;
        }
      }
    }
    .sign-btn {
      margin-top: 30px;
    }
    .register {
      margin-top: 30px;
      text-align: center;
    }
  }
}



.password-strength-group {
  .password-strength-meter {
    width: 100%;
    transition: height 0.3s;

    display: flex;
    justify-content: stretch;

    //offseting meter-block gaps

    .meter-block {
      height: 4px;
      background: #ccc;

      margin-right: 6px;
      flex-grow: 1;

      &:last-child {
        margin: 0;
      }
    }
  }

  .password-strength-message {
    font-weight: 20px;
    height: 1em;
    text-align: right;
    transition: all 0.5s;
    margin-top: 3px;

    position: relative;
    .message-item {
      font-size: 12px;
      position: absolute;
      right: 0;
      opacity: 0;
      transition: opacity 0.2s;
    }
  }

  //define activated state:

  @for $i from 1 through 4 {
    &[data-strength="#{$i}"] {

      .meter-block:nth-child(-n + #{$i}) {
        //Switch cases color
        @if ($i == 1) {
          background: #cc3d04; //red
        } @else if($i == 2) {
          background: #ffc43b; //yellow
        } @else if($i == 3) {
          background: #9ea60a; //Yellow-green
        } @else if($i == 4) {
          background: #289116; //yellow
        }
      }

      .message-item:nth-child(#{$i}) {
        opacity: 1;
      }
    }
  }
}
