.dropzone {
  border: 2px dashed $borde_menu;
  border-radius: 5px;
  background: #F6F7FB;
  margin-bottom: 40px;
  text-align: center;
  font-size: 22px;
  .dz-message {
    font-weight: 400;
    text-align: center;
    margin: 2em 0;
    .note {
      font-weight: 200;
      display: block;
      margin-top: 1.4rem;
    }
    .dz-button {
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
    }
  }
}
.btnDescargar {
  border-radius: 0;
  border: 0;
  font-size: 18px;
  line-height: 25px;
  color: #fff;
  background: $rojo;
  padding-left: 0;
  box-shadow: none;
  padding: 15px;
  text-align: center;
  border-radius: 5px;
  display: block;
  text-align: center;
  &:hover{
    color:#fff;
    background-color: $fondo_submenu;
  }
  i {
      margin-right: 10px;
  }
}