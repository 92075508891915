.btn {
  @include transition(0.3s ease-in-out);
  i {
    margin-right: 5px;
  }
}
button[type="submit"].btn,
input[type="submit"].btn,
button.btn-login {
  border-radius: 25px;
  font-size: 18px;
  height: 55px;
  width: 250px;
}
.btn-icon {
  width: 30px;
  height: 30px;
  padding: 0;
  text-align: center;
  line-height: 32px;
  font-size: 14px;
  display: inline-block;
  @include border-radius(50%);
  @include transition(all 0.5s ease-in-out);
  i {
    margin-right: 0 !important;
  }
}
.social-btn {
  width: 30px;
  height: 30px;
  padding: 0;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  color: $white;
  i {
    margin: 0;
  }
}
.btn-primary,
.btn-success,
.btn-info,
.btn-warning,
.btn-danger,
.btn-inverse,
.btn-purple,
.btn-pink,
.btn-twitter,
.btn-facebook,
.btn-linkedin,
.btn-google,
.btn-dribbble,
.btn-instagram {
  color: $white;
}
.btn {
  &-theme {
    background: $rojo;
    color: #fff;
  }
  &default {
    background-color: #dae6ec;
    border-color: #dae6ec;
    &:focus,
    &:hover,
    &:active,
    &.active {
      background-color: #dae6ec;
      border-color: #c2ced4;
      color: $white !important;
    }
  }
  &-primary {
    background-color: $rojo;
    border: 1px solid $rojo;
    &:hover,
    &:focus,
    &.active {
      background-color: darken($rojo, 5%);
      border: 1px solid darken($rojo, 5%);
      color: $white !important;
    }
  }
  &-success {
    background-color: $success;
    border: 1px solid $success;
    &:hover,
    &:focus,
    &.active {
      background-color: darken($success, 5%);
      border: 1px solid darken($success, 5%);
      color: $white !important;
    }
  }
  &-info {
    background-color: $info;
    border: 1px solid $info;
    &:hover,
    &:focus,
    &.active {
      background-color: darken($info, 5%);
      border: 1px solid darken($info, 5%);
      color: $white !important;
    }
  }
  &-warning {
    background-color: $warning;
    border: 1px solid $warning;
    &:hover,
    &:focus,
    &.active {
      background-color: darken($warning, 5%);
      border: 1px solid darken($warning, 5%);
      color: $white !important;
    }
  }
  &-danger {
    background-color: $danger;
    border: 1px solid $danger;
    &:focus,
    &:hover,
    &.active {
      background-color: darken($danger, 5%);
      border: 1px solid darken($danger, 5%);
      color: $white !important;
    }
  }
  &-inverse {
    background-color: $inverse;
    border: 1px solid $inverse;
    &:hover,
    &:focus,
    &.active {
      background-color: darken($inverse, 5%);
      border: 1px solid darken($inverse, 5%);
      color: $white !important;
    }
  }
  &-purple {
    background-color: $purple;
    border: 1px solid $purple;
    &:hover,
    &:focus,
    &.active {
      background-color: darken($purple, 5%);
      border: 1px solid darken($purple, 5%);
      color: $white !important;
    }
  }
  &-pink {
    background-color: $pink;
    border: 1px solid $pink;
    &:hover,
    &:focus,
    &.active {
      background-color: darken($pink, 5%);
      border: 1px solid darken($pink, 5%);
      color: $white !important;
    }
  }
  &-facebook {
    background: $facebook;
  }
  &-twitter {
    background: $twitter;
  }
  &-dribbble {
    background: $dribbble;
  }
  &-linkedin {
    background: $linkedin;
  }
  &-google {
    background: $google;
  }
  &-instagram {
    background: $instagram;
  }
}
.template-demo {
  .btn {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
.btn-group {
  .btn {
    margin: 0;
  }
}

#accordion{
  .btn-link{
    color: $rojo;
    font-weight: 600;
  }
}