.pagination {
  .page-item {
    padding: 5px;
    .page-link {
      outline: initial !important;
      box-shadow: none !important;
      line-height: 18px;
      min-width: 30px;
      text-align: center;
      height: 30px;
      padding: 6px 0px;
      border: none;
      background-color: #eaeaea;
      color: #3e5569;
      @include border-radius(30px);
      &.first,
      &.last {
        color: $white;
        background-color: #3e5569;
      }
    }
    &.active {
      .page-link {
        background-color: $primary;
        color: $white;
      }
    }
  }
}
