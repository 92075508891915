.pagos-dinamicos {
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.05em;
  font-size: 1rem;
  color: #707070;

  .container {
    max-width: 500px;
    margin: auto;
    border: 1px solid #9f9f9f;
    padding: 50px;
    margin-top: 100px;
    line-height: 26px;

    h1 {
      text-align: center;
      margin-top: 0;
    }
  }

  input[type="submit"] {
    background: $rojo;
    color: #e2d4ba;
    border: none;
    padding: 0.6em 2em;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    height: 2.8em !important;
    margin-top: 1.5em;
    font-size: 20px;
    opacity: 0.8;
    transition: opacity 0.3s ease-in-out, box-shadow 0.6s ease-in-out 0.2s;
    text-transform: uppercase;
    letter-spacing: 0.4px;
  }
}
