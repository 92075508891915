.galeria {
  position: relative;
  ul {
    display: flex;
    justify-content: space-between;
    width: 60%;
    li {
      position: relative;
      margin-right: 20px;
      max-width: 50%;
      div {
        position: relative;
      }
    }
  }
  .btnEliminarImagen {
    background: none;
    border: 0;
    padding: 0;
    position: absolute;
    right: -10px;
    top: -10px;
  }
}

table.dataTable tbody tr {
  input[type="checkbox"] {
    visibility: hidden;
  }

  label {
    display: block;
    span {
      padding: 25px 0;
      display: block;
      cursor: pointer;
    }
  }
  &.selected {
    background-color: $rojo !important;
  }
}

.galeria-imagenes {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  li {
    display: flex;
    padding: 1%;
    width: 15%;

    cursor: pointer;
    position: relative;
    border: 2px solid #fff;
    overflow: hidden;
    cursor: pointer;
    label {
      display: block;
      overflow: hidden;
      font-size: 11px;
      line-height: 1.5em;
    }
    img {
      cursor: pointer;
      z-index: 999;
      position: relative;
    }
    input[type="radio"] {
      position: absolute;
      left: 15px;
      z-index: 1;
    }
  }
  li.imagenes-seleccionadas {
    border: 2px solid $rojo;
  }
}
