$color_1: #a5757c;
$color_2: red;
$color_3: #bd910b;
$color_4: #fff;
$color_5: #7ea881;
$color_6: #a5757c;
$color_7: #000;
$color_8: #663f46;
$font_family_1: "Raleway", sans-serif;
$border_color_1: #bfbfbf;
$border_color_2: #dddddd;

.login {
  max-width: 500px;
  margin: 200px auto;

  .card {
    position: relative;
    width: 100%;
    border: none;
    margin-bottom: 30px;
    box-shadow: 0 6px 0 0 rgba(0, 0, 0, 0.01), 0 0px 50px 0 rgba(0, 0, 0, 0.15);
    transition: all 0.5s ease-in-out;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    padding: 40px;
    border-radius: 0;
  }

  button[type="submit"] {
    color: #e2d4ba;
    border: none;
    padding: 0.6em 2em;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    height: 2.8em !important;
    margin-top: 1.5em;
    font-size: 20px;
    opacity: 0.8;
    transition: opacity 0.3s ease-in-out, box-shadow 0.6s ease-in-out 0.2s;
    text-transform: uppercase;
    letter-spacing: 0.4px;
    border-radius: 0;
    background: $rojo;
  }

  input[type="password"] {
    background: #9f9f9f;
    border: none;
    color: rgb(30, 28, 25);
    font-size: 1.2em;
    font-weight: 300;
    height: 3em;
    line-height: 1.4em;
    opacity: 0.5;
    padding-left: 1em;
    transition-duration: 0.35s;
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.46, 0.03, 0.52, 0.96);
    width: 100%;
    border-radius: 0px;
    cursor: pointer;
  }

  label {
    background: #fff;
    border-radius: 1em;
    color: #bd910b;
    display: inline-block;
    font-size: 1.13em;
    font-weight: 300;
    left: 10px;
    line-height: 0.8em;
    padding: 0.4em 1em;
    position: relative;
    top: 18px;
    width: auto;
    z-index: 2;
  }
}

.viaje-information {
  font-size: 18px;

  input[type="text"] {
    width: 100%;

    @include mq("tablet") {
      width: 95%;
    }
  }

  .titulo {
    text-align: center;
    font-weight: bold;
    color: $color_1;
    font-size: 30px;
    margin: 20px auto;
  }

  table {
    &.flight_information {
      input[type="text"] {
        width: 100%;
      }

      @include mq("tablet") {
        td {
          padding-right: 15px;
        }

        td:nth-child(1),
        td:nth-child(2),
        td:nth-child(3) {
          width: 10%;
        }

        td:nth-child(4),
        td:nth-child(5) {
          width: 9%;
        }

        td:nth-child(6) {
          width: 2%;
        }

        td:nth-child(7) {
          width: 1%;
          padding-right: 0;
          min-width: 46px;
        }
      }
    }

    @include mq("tablet-wide", "max") {
      td {
        display: block !important;
        width: 100% !important;
      }
    }
  }

  .form_configura_tu_viaje {
    width: auto;
    float: none;
    margin: 0px;
    height: auto;
    padding-bottom: 10px;
    width: auto;
    float: none;
    margin: 0px;
    height: auto;
    padding-bottom: 10px;
  }

  .form_contacto {
    position: relative;

    @include mq("tablet-wide") {
      padding: 30px;
      padding-bottom: 70px;
      margin-left: 30px;
    }
  }

  .form_configura_tu_viaje {
    overflow: hidden;
  }

  .titulo_campo {
    font-size: 22px;
    margin-bottom: 20px;
    margin-top: 40px;
    font-weight: bold;
    color: $color_3;
    border-bottom: 2px solid $color_3;
    padding-bottom: 20px;

    @include mq("tablet", "max") {
      font-size: 20px;
      margin-top: 20px;
      padding-bottom: 10px;
    }

    span {
      background: #d8be70;
      color: #fff;
      font-weight: bold;
      border-radius: 100%;
      width: 25px;
      height: 25px;
      margin-right: 10px;
      line-height: 25px;
      font-size: 17px;
      display: inline-block;
      text-align: center;
    }
  }

  label {
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 5px;
    display: block;
    margin-top: 10px;
    color: $color_3;
  }

  @include mq("tablet") {
    .izquierda_documentos {
      float: left;
      width: 60%;
      margin-top: 40px;
      margin-right: 5%;
      padding-right: 5%;
      border-right: 1px solid #ddd;
    }

    .derecha_documentos {
      float: left;
      width: 35%;
    }
  }

  i {
    color: $color_2;
  }

  i.fa-star {
    color: $color_3;
  }

  p {
    font-size: 15px;
    line-height: normal;

    @include mq("tablet", "max") {
      font-size: 14px;
    }
  }

  padding-bottom: 40px;

  button {
    border: none;
    background: $color_8;
    text-align: center;
    max-width: 270px;
    padding: 5px 15px;
    margin: auto;
    font-size: 18px;
    font-weight: normal;
    color: $color_4;
    text-align: center;
    position: relative;
    left: 15px;
    top: -5px;
    height: 45px;
    cursor: pointer;

    @include mq("tablet", "max") {
      top: 0px;
      left: 0px;
      display: block;
      width: 100%;
      margin: 20px auto !important;
      max-width: 100% !important;
    }

    i {
      color: #a5757c;
      font-size: 18px;
    }
  }

  input[type="submit"] {
    border: none;
    background: $color_8;
    display: block;
    text-align: center;
    width: 270px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 15px;
    margin: auto;
    font-size: 20px;
    color: $color_4;
    text-align: center;
    float: right;
    margin-right: 20px;
    margin-bottom: 20px;
    cursor: pointer;

    @include mq("tablet", "max") {
      top: 0px;
      left: 0px;
      display: block;
      width: 100%;
      margin: 20px auto;
      max-width: 100% !important;
    }

    &.btnSave {
      margin-top: 80px;

      @include mq("tablet", "max") {
        margin: 20px auto 40px;
      }
    }
  }

  .btnConfiguraGrande {
    border: none;
    border: 1px solid $color_8;
    display: block;
    text-align: center;
    width: 270px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 15px 5px;
    margin: auto;
    font-size: 17px;
    color: $color_8;
    text-align: center;
    float: right;
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;

    @include mq("tablet", "max") {
      top: 0px;
      left: 0px;
      display: block;
      width: 100%;
      margin: 20px auto;
      max-width: 100% !important;
    }

    &:hover {
      text-decoration: none;
      color: #fff;
      background: $color_8;
    }

    i {
      color: $color_4;
    }

    &.btnConfiguraGrandeAzul {
      background: #1db1ed;
    }
  }

  .tabla-trip {
    margin-bottom: 40px;

    td {
      @include mq("tablet") {
        padding-right: 15px;

        td:nth-child(1),
        td:nth-child(2),
        td:nth-child(3) {
          width: 20%;
        }

        td:nth-child(4) {
          width: 30%;
        }

        td:nth-child(5) {
          width: 1%;

          .removePass {
            height: 135px;
            margin-top: 35px;

            i {
              top: 55px;
            }
          }
        }
      }
    }

    @include mq("tablet-wide", "max") {
      td {
        display: block !important;
        width: 100% !important;
      }
    }

    i {
      font-size: 32px;
      color: $color_3;
      float: left;
      margin-right: 20px;
    }

    p {
      margin: 0;
      margin-right: 20px;

      @include mq("tablet", "max") {
        margin: 20px 0;
      }
    }

    input[type="text"] {
      /*width: 150px;*/
      width: 100%;
    }

    .form-group {
      margin: 0;

      .input-group {
        margin: 0;

        input[type="text"] {
          background: #e7e7e7;
          border-color: #e7e7e7;
          width: 100px !important;
        }

        .file-upload-info.exists {
          background: #efebdd !important;
        }

        .file-upload-browse {
          background: #3e3e3e;
          border-radius: 0px 4px 5px 0px;
          color: #fff;
          font-size: 14px;
          top: 0;
          left: 0;
          height: 49px;
          padding: 5px 10px;

          i {
            font-size: 16px !important;
            color: #fff !important;
            margin-right: 10px;
          }
        }
      }
    }
  }

  select {
    width: 100px;
    background: #fff;
    border-color: $border_color_1;
  }

  input[type="text"] {
    &:focus {
      background: #fff;
      border-color: $border_color_1;
    }

    &:disabled {
      background: #dddddd;
      border-color: $border_color_2;
    }

    &:disabled.pagado {
      color: $color_5;
      background: #cfcfcf;
    }
  }

  button[type="submit"] {
    cursor: pointer;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 50px;
    text-align: center;

    i {
      color: #fff;
      margin-left: 5px;
    }

    &:disabled {
      background: #b7b7b7;
      cursor: default;
    }
  }

  h4 {
    font-size: 16px;
    margin: 15px 0px 10px;
    color: $color_1;
  }

  .viaje_validar_password {
    border: none;
    background: $color_8;
    -webkit-box-shadow: -3px 3px 1px 1px #9b9c94;
    box-shadow: -3px 3px 1px 1px #9b9c94;
    display: block;
    text-align: center;
    width: 270px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 15px;
    margin: auto;
    border-radius: 4px;
    font-size: 20px;
    color: $color_4;
    text-align: center;
    float: right;
    margin-right: 20px;
    margin-top: 40px;
    margin-bottom: 20px;
    cursor: pointer;
    float: none;
    margin: 20px auto;
    width: 200px;
    font-size: 18px;
    padding: 10px;

    @include mq("tablet", "max") {
      top: 0px;
      left: 0px;
      display: block;
      width: 100%;
      margin: 20px auto;
      max-width: 100% !important;
    }
  }

  .borde-amarillo {
    margin-bottom: 0px;
    margin-top: 40px;
    font-weight: bold;
    border-bottom: 1px solid #c8c5c5;
  }

  .tabla_validar_password {
    margin: auto;
    width: 90%;
    margin-top: 30px;
  }

  .btnDeletePassenger {
    position: relative;
    top: 8px;
    left: 0px;
    color: #a5757c !important;
    display: block;
    padding-left: 20px;
  }

  .removePass {
    display: block;
    border-left: 1px solid #c8c5c5;
    height: 45px;
    margin-top: 40px;

    @include mq("tablet", "max") {
      margin-top: 10px;
      border: 0px;
      margin-bottom: 20px;
    }

    span {
      display: none;
    }
  }

  .izquierda_documentos {
    td {
      padding: 10px 3px 5px 0px;
      font-size: 18px;
    }

    i {
      margin-right: 5px;
      margin-bottom: 5px;
      color: $color_8;

      &.fa-star {
        color: $color_1;
      }
    }

    a {
    }
  }

  .pago-trip {
    margin-bottom: 40px;
    width: 100%;

    @include mq("tablet") {
      td {
        padding-right: 15px;
      }

      td:nth-child(1),
      td:nth-child(3) {
        width: 35%;
      }

      td:nth-child(2) {
        width: 18%;
      }
    }

    input[type="text"] {
      font-size: 18px;
      width: 100%;

      @include mq("tablet", "max") {
        font-size: 14px;
      }
    }

    span.nombre {
      margin: 30px auto 10px;
      display: block;
      color: $color_1;
      font-size: 16px;
    }

    tr {
      td {
        padding-bottom: 10px;
      }

      td.concepto {
        p {
          margin: 0;
          margin-right: 15px;
          padding: 10px;
          border-left: 5px solid #bd910b;
          border-bottom: 2px solid #e5d5d8;
          min-height: 50px;
        }
      }
    }
  }

  .payment-text {
    padding: 3%;
    background: #f5efdd;
    border: 1px solid $color_3;
    font-size: 18px;
    line-height: normal;
    text-align: center;

    p {
      margin: 0;
      font-size: 18px;
      line-height: 27px;
      color: $color_3;

      @include mq("tablet", "max") {
        font-size: 16px;
        line-height: normal;
      }
    }
  }

  .display-mobile {
    display: none;
  }

  textarea {
    border-radius: 4px;
    border: 1px solid #e7e7e7;
    padding: 10px 8px 10px 8px;
    font-size: 18px;
    width: 90%;
  }

  input[type="text"] {
    border-radius: 0px;
    border: 1px solid #e7e7e7;
    padding: 10px 8px 10px 8px;
    font-size: 18px;
    /*width: 90%;*/
    background: #e7e7e7;
    position: relative;

    @include mq("tablet", "max") {
      font-size: 16px;
    }
  }

  select {
    border-radius: 0px;
    border: 1px solid #e7e7e7;
    padding: 10px 8px 10px 8px;
    font-size: 18px;
    width: 100%;
    height: 49px;
    background: #e7e7e7;
    @include mq("tablet", "max") {
      font-size: 16px;
    }
  }

  input[type="password"] {
    border-radius: 0px;
    border: 1px solid #e7e7e7;
    padding: 10px 8px 10px 8px;
    font-size: 18px;
    width: 100%;
    @include mq("tablet", "max") {
      font-size: 16px;
    }
  }

  input[type="email"] {
    border-radius: 0px;
    border: 1px solid #e7e7e7;
    padding: 10px 8px 10px 8px;
    font-size: 18px;
    width: 90%;
    @include mq("tablet", "max") {
      font-size: 16px;
    }
  }

  input[type="tel"] {
    border-radius: 0px;
    border: 1px solid #e7e7e7;
    padding: 10px 8px 10px 8px;
    font-size: 18px;
    width: 90%;
    @include mq("tablet", "max") {
      font-size: 16px;
    }
  }

  .file_1 {
    border-radius: 4px;
    border: 1px solid #e7e7e7;
    padding: 10px 8px 10px 8px;
    font-size: 14px;
    width: 90%;
  }

  .borde {
    border-bottom: 1px solid #bfbfbf;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  @include mq("tablet", "max") {
    .viaje-information button {
      margin-left: 0 !important;
    }
  }

  @media (max-width: 640px) {
    .viaje-information {
      &::-webkit-input-placeholder {
        font-size: 15px;
      }

      &:-moz-placeholder {
        font-size: 15px;
      }

      &::-moz-placeholder {
        font-size: 15px;
      }

      &:-ms-input-placeholder {
        font-size: 15px;
      }

      .izquierda_documentos {
        td {
          display: inline-table;
          width: auto;
        }
      }

      input[type="submit"] {
        float: none;
        width: 100%;
      }

      label {
        margin-top: 10px;
      }

      .titulo {
        font-size: 20px;
      }

      .titulo_campo {
        font-size: 16px;
      }

      .btnConfiguraGrande {
        margin: auto;
        margin-top: 30px;
        font-size: 17px;
        width: 250px;
        float: none;
      }
    }

    .btnDeletePassenger {
      display: none;
    }

    .removePass {
      i {
        display: none;
      }

      span {
        background: #a5757c;
        width: 100%;
        display: block;
        border-radius: 4px;
        text-align: center;
        color: $color_4;
        font-weight: bold;
        padding: 10px 0px;
        font-size: 16px;
        margin-top: 10px;
      }
    }
  }
}

.group {
  position: relative;

  input[type="text"] {
    padding-right: 20px;
  }

  i {
    position: absolute;
    color: #c8c5c5 !important;
    z-index: 999;
    top: calc(50% - 9px);
    right: 10px;
    font-size: 18px !important;
  }
}
