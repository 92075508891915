$header_menu: #004F2D;
$fondo_menu: #3d3d3d;
$fondo_submenu: #004F2D;
$titulo_menu: #F2F3F5;
$borde_menu: #004F2D;

$negro: #000000;
$negro_menu: #1e1e1e;
$menu_hover: #4d4d4d;
$fondo_gris: #f1f1f3;
$titulos: #4d4f5c;
$fuente_formularios: #a4afb7;
$botones_fondo: #000;
$azul: #43425d;
$rojo: #004F2D;
$rojo_fondo: #f6f6e6;
$verde: #8acb73;
$verde_fondo: #e9f6e6;
$rosa: #c67d7d;
// Predefined Break-points
$breakpoint-tablet: 768px;
$breakpoint-portatil: 992px;
$breakpoint-desktop: 1520px;
$font: "Roboto";

//
// These variables can be used to customize the switch component.
//

$font-size-base: 1rem;
$font-size-lg: ($font-size-base * 1.25);
$font-size-sm: ($font-size-base * 0.875);
$input-height: 2.375rem;
$input-height-sm: 1.9375rem;
$input-height-lg: 3rem;
$input-btn-focus-width: 0.2rem;
$custom-control-indicator-bg: #dee2e6;
$custom-control-indicator-disabled-bg: #e9ecef;
$custom-control-description-disabled-color: #868e96;
$white: white;
$theme-colors: (
  "primary": #04b1d1
);

$switch-height: calc(#{$input-height} * 0.8) !default;
$switch-height-sm: calc(#{$input-height-sm} * 0.8) !default;
$switch-height-lg: calc(#{$input-height-lg} * 0.8) !default;
$switch-border-radius: $switch-height !default;
$switch-bg: $custom-control-indicator-bg !default;
$switch-checked-bg: map-get($theme-colors, "primary") !default;
$switch-disabled-bg: $custom-control-indicator-disabled-bg !default;
$switch-disabled-color: $custom-control-description-disabled-color !default;
$switch-thumb-bg: $white !default;
$switch-thumb-border-radius: 50% !default;
$switch-thumb-padding: 2px !default;
$switch-focus-box-shadow: 0 0 0 $input-btn-focus-width
  rgba(map-get($theme-colors, "primary"), 0.25);
$switch-transition: 0.2s all !default;

.text-gris {
  color: $fuente_formularios !important;
}
