/*!
  This is an important (!) comment and will be preserved.
*/

/*
  This comment is not important and could be removed.
*/

@import url("fontawesome/all.css");
@import "variables";
@import "mixins";
@import "settings";
@import "layout/*";
